<template>
<div class="cabinet__content history">
    <h2 class="cabinet__header mb-3 d-none d-md-block">История заказов</h2>
    <div class="row">
        <div class="col-md-12">
            <table class="table mt-2 d-none d-md-table">
                <thead>
                    <tr>
                        <th scope="col" style="width:20%;">Дата заказа</th>
                        <th scope="col">Время заказа</th>
                        <th scope="col">Номер заказа</th>
                        <th scope="col">Стоимость</th>
                        <th scope="col">Статус</th>
                        <th scope="col">Оценка</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(item) in $store.state.cabinet.orders.orders">
                        <tr @click="toggle(item.id)" :class="{ opened: opened.includes(item.id) }" :key="item.id">
                            <td>{{$moment(item.date_day).format('DD.MM.YYYY')}}<br></td>
                            <td>{{item.date_time}}</td>
                            <td>№{{item.unique_code}}</td>
                            <td>{{item.price}} руб.</td>
                            <td><span class="status" :class="item.status.name=='Собрано'?'assemled':''">{{item.status.name}} <img :src="require('../assets/true.svg')" v-if="item.status.name=='Собрано'" /></span></td>
                            <td style="position:relative"><span class="stars" @click="setIdForStars(item.id)">
                                    <star-rating :increment="0.5" :rating="item.stars" :star-size="20" text-class="d-none" @rating-selected="setRating"></star-rating>
                                </span><span class="disablerate" v-if="item.status.name!='Доставлен'"></span></td>
                            <td><span class="toggle"></span></td>
                        </tr>
                        <tr v-if="opened.includes(item.id)" :key="item.id">
                            <td colspan="3">
                                <p v-for="product in item.products" :key="product.id">{{product.name}} ({{product.quantity}})</p>
                            </td>

                            <td>
                                <p v-for="product in item.products" :key="product.id">{{product.full_price}} руб.</p>
                            </td>
                            <td colspan="2"><span class="payMethod">банковской картой</span></td>

                        </tr>
                        <tr v-if="opened.includes(item.id)&&item.status.name=='К сборке'" :key="item.id">
                            <td colspan="6">

                                <div class="history__cancel-order-header">Отменить заказ?</div>
                                <div class="history__cancel-order pb-3">
                                    <button class="btn-oval-low  btn-oval-low--orange me-md-2 mb-2 mb-md-0" @click="cancelOrder(item.id)">Вернуть бонусами</button>
                                    <button class="btn-oval-low  btn-oval-low--border me-md-3" @click="cancelToRegist(item.id)">Вернуть на карту</button>
                                    <p class="mt-2 mt-md-0">
                                        Средства, которые получены в виде бонусов, могут быть потрачены на следующий заказ
                                    </p>
                                </div>

                            </td>

                        </tr>
                        <tr v-if="opened.includes(item.id) && item.can_change" :key="item.id">
                            <td colspan="6">
                                <div class="history__chnage-order">
                                    <button @click="setCartItemId(item.cart_item_id, item.id)" class="btn-oval-low btn-oval-low--dark">Изменить заказ</button>
                                    <p class="mt-2 mt-md-0"><b>Внимание:</b> вы можете изменить заказ до 20:00 за день до его получения</p>
                                </div>
                            </td>

                        </tr>
                    </template>
                </tbody>
            </table>
            <!-- ////////////Mobile////////////////////////////////////////////////////// -->
            <table class="table mt-2 d-md-none">

                <tbody>
                    <template v-for="item in $store.state.cabinet.orders.orders">
                        <tr @click="toggle(item.id)" class="tableHeader" :class="{ opened: opened.includes(item.id) }" :key="item.id">
                            <td>{{$moment(item.date_day).format('DD.MM.YYYY')}}
                                <br>{{item.date_time}}
                            </td>

                            <td>№{{item.unique_code}}</td>
                            <td>{{item.price}} руб. <span class="statusСollected">{{item.status.name}}</span></td>

                        </tr>
                        <tr v-if="opened.includes(item.id)" :key="item.id">
                            <td colspan="2">
                                <p v-for="product in item.products" :key="product.id">{{product.name}} ({{product.quantity}})</p>
                            </td>

                            <td>
                                <p v-for="product in item.products" :key="product.id">{{product.full_price}} руб.</p>
                            </td>
                        </tr>
                        <tr v-if="opened.includes(item.id)" :key="item.id">
                            <td colspan="3">банковской картой</td>
                        </tr>
                        <tr v-if="opened.includes(item.id)&&item.status.name!='Отменен'" :key="item.id">
                            <td colspan="3" style="position:relative;"><span class="stars" @click="setIdForStars(item.id)">
                                    <star-rating :increment="0.5" :rating="item.stars" :star-size="30" text-class="d-none" @rating-selected="setRating"></star-rating>
                                </span><span class="disablerate" v-if="item.status.name!='Доставлен'"></span></td>
                        </tr>
                        <tr v-if="opened.includes(item.id) && item.can_change" :key="item.id" >
                            <td colspan="6">
                                <div class="history__chnage-order">
                                    <button @click="setCartItemId(item.cart_item_id, item.id)" class="btn-oval-low btn-oval-low--dark">Изменить заказ</button>
                                    <p class="mt-2 mt-md-0"><b>Внимание:</b> вы можете изменить заказ до 20:00 за день до его получения</p>
                                </div>
                            </td>

                        </tr>
                         <tr v-if="opened.includes(item.id)&&item.status.name=='К сборке'" :key="item.id">
                       <td colspan="6">
                                <div class="history__cancel-order-header">Отменить заказ?</div>
                                <div class="history__cancel-order pb-3">
                                    <button class="btn-oval-low  btn-oval-low--orange me-md-2 mb-2 mb-md-0" @click="cancelOrder(item.id)">Вернуть бонусами</button>
                                    <button class="btn-oval-low  btn-oval-low--border me-md-3" @click="cancelToRegist(item.id)">Вернуть на карту</button>
                                    <p class="mt-2 mt-md-0">
                                        Средства, которые получены в виде бонусов, могут быть потрачены на следующий заказ
                                    </p>
                                </div>

                            </td>

                        </tr>
                        <!-- <tr v-if="opened.includes(item.id)&&item.status.name=='К сборке'" :key="item.id">
                            <td colspan="6">

                                <div class="history__cancel-order-header">Отменить заказ?</div>
                                <div class="history__cancel-order pb-3">
                                    <button class="btn-oval-low  btn-oval-low--orange me-md-2 mb-2 mb-md-0" @click="cancelOrder(item.id)">Вернуть бонусами</button>
                                    <button class="btn-oval-low  btn-oval-low--border me-md-3" @click="cancelToRegist(item.id)">Вернуть на карту</button>
                                    <p class="mt-2 mt-md-0">
                                        Средства, которые получены в виде бонусов, могут быть потрачены на следующий заказ
                                    </p>
                                </div>

                            </td>

                        </tr> -->
                    </template>
                </tbody>
            </table>
            <div class="row">
  
            </div>
        </div>
    </div>
    <popover name="info" :width="330">
        Забрать заказ можно до <b>17:00</b>.
        В противном случае, возврат средств будет невозможен
    </popover>
</div>
</template>

<script>
import StarRating from 'vue-star-rating'
// import Navbar from '@/components/Navbar.vue'
// import Footer from '@/components/Footer.vue'
// import NavbarVertical from '@/components/NavbarVertical.vue'
export default {
    name: "Cart",
    components: {
        StarRating
        // Navbar,
        // Footer,
        // NavbarVertical,
    },
        head: {
        title: {
            inner: 'История заказов',
            separator: '-',
            complement: 'Ели Млели'
        },
    },
    data() {
        return {
            opened: [],
          
            idForStars: null,
            newCountOfStars: null
        };
    },
    async mounted() {
        await this.$store.dispatch("actionGetOrderList");
    },
    watch: {
        async idForStars() {
            if (this.idForStars === null) return
            const data = {
                order_id: this.idForStars,
                stars: this.newCountOfStars
            }
            await this.$store.dispatch("actionUpdateStars", data)

        },
        async newCountOfStars() {
            if (this.idForStars === null) return
            const data = {
                order_id: this.idForStars,
                stars: this.newCountOfStars
            }
            await this.$store.dispatch("actionUpdateStars", data)
        }
    },
    methods: {
        setCartItemId(cartItemId, id) {
            localStorage.setItem("cartEditId", cartItemId);
            this.$store.commit('SET_CART_ID_EDIT', cartItemId);
            this.$router.push(`/edit-cart/${id}`)
        },
        toggle(id) {
            const index = this.opened.indexOf(id);
            if (index > -1) {
                 this.opened.length =0
                this.opened.splice(index, 1)
                   
            } else {
                this.opened.length =0
                this.opened.push(id)
                    
            }
        },
        setRating(count) {
            this.newCountOfStars = count
            //this.rating= rating;
        },
        setIdForStars(id) {
            this.idForStars = id
        },
        async cancelOrder(id) {
            await this.$store.dispatch("actionCancelOrder", {
                order_id: id,
                type: 'bonus'
            })
             this.$toast("Заказ отменен");
            await this.$store.dispatch("actionGetOrderList");
        },
        countTotalSum(product) {
            let sum = 0
            product.forEach(element => {
                sum += Number(element.price)
            });
            return sum;
        },
        async cancelToRegist(id){
             await this.$store.dispatch("actionCancelOrder", {
                order_id: id,
            })
             this.$toast("Заказ отменен");
             await this.$store.dispatch("actionGetOrderList")
        //   const data = {
        //         'status_id': 6,
        //         'order_id': id
        //     }
        //     //await this.$store.dispatch("actionChangeStatus", data);
        //      await this.$store.dispatch("actionGetOrderList");
        },
        addNulls(id){
            let idNull = null;
            if(id.toString().length==2){
              idNull = `00${id}`
            } else if(id.toString().length==3){
               idNull = `0${id}`
            }
            return idNull
        }

    }
}
</script>

<style lang="scss" scoped>
.cabinet {
    display: flex;
    justify-content: space-between;
}

.container {}

.cabinet {
    margin-top: 85px;
    padding-bottom: 58px;

    @include media-breakpoint-down(md) {
        padding-bottom: 110px;
        margin-top: 25px;
        flex-direction: column;
    }

    &__nav {
        width: 250px;
    }

    &__content {
        width: calc(100% - 326px);
        background: #FFFFFF !important;
        box-shadow: -10px 6px 23px rgba(156, 156, 156, 0.1);
        border-radius: 12px;
        padding: 40px 50px 60px 49px;

        @include media-breakpoint-down(md) {
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
            padding-top: 10px;
        }

    }

    &__header {
        margin-bottom: 29px;
        font-size: 24px;
    }
}

#app>div {

    background: #F5F5F5;
}

.footer {
    background: #fff;
}

.top-navbar {
    background: #fff !important;
}

// .cabinet {

//     &__content {}

//     &__header {}
// }
.table {
    font-family: Montserrat;
    font-size: 14px;
}

.toggle {
    border: solid #777777;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
}

tr {
    cursor: pointer;
}

.opened {
    font-weight: bold;

    &+tr {
        cursor: inherit;

        p {
            line-height: 28px;
            padding: 0;
            margin: 0;
        }
    }

    .toggle {
        transform: rotate(225deg);
    }
}

.history {

    &__chnage-order {
        display: flex;
        align-items: center;
        margin-top: 3px;
        border-bottom: 1px solid #E6E6E6;
        padding-bottom: 20px;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: center;
            text-align: center;
            border-bottom: 0;
            padding-bottom: 7px;
        }

        p {
            margin: 0;
            font-size: 14px;
            padding-left: 25px;

            @include media-breakpoint-down(md) {
                max-width: 295px;
            }
        }
    }

    &__cancel-order-header {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        // margin-top: 18px;

        @include media-breakpoint-down(md) {
            width: 100%;
            text-align: center;
        }
    }

    &__cancel-order {
        margin-top: 4px;
        display: flex;
        align-items: center;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        p {
            margin: 0;
            font-size: 14px;
            max-width: 387px;

            @include media-breakpoint-down(md) {
                max-width: 295px;
            }
        }

        button {
            @include media-breakpoint-down(md) {
                width: 200px;
            }
        }
    }
}

thead {
    font-family: Montserrat;
    color: #9A9A9A;
    font-size: 14px;
    font-weight: 500;

}

.table> :not(:first-child) {
    border-top: 1px solid #E5E5E5 !important;
}

#app>div {

    background: url(../assets/history-bg.svg) 95% 70% no-repeat #F5F5F5;
}

td {
    padding: 20px 0 20px !important;

    @include media-breakpoint-down(md) {
        padding: 10px 0 10px !important;
    }
}

th {
    padding: 0 0 20px !important;
}

.table> :not(caption)>*>* {
    @include media-breakpoint-down(md) {
        border: none;
    }
}

.tableHeader {
    border-bottom: 1px solid #ECECEC;
}

.statusСollected {
    display: block;
    color: #009017;
    font-weight: bold;
}

.statusPayed {
    display: block;
    font-weight: bold;
}

.stars {
    @include media-breakpoint-down(md) {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    img {
        @include media-breakpoint-down(md) {
            width: 25px;
            height: auto;

        }
    }
}

[data-popover='info'] {

    margin-bottom: 30px;
    //   width: 100% !important;
    //   max-width: 280px !important;
    font-family: Montserrat;
    padding: 21px 15px 33px 21px;
    font-size: 14px;
    line-height: 142.69%;
    /* or 20px */

    color: #000000;

}

.disablerate {
    display: block;

    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

}
.assemled{
    color: #009017;
    font-weight: 700;
}
.payMethod{
    display: inline-block;
    max-width: 120px;
}
</style>
